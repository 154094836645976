.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2em 5em 16em auto auto;
}

.banner {
  grid-column: 1 / -1;
}

.header {
  grid-column: 1 / -1;
}

.content {
  grid-column: 1 / -1;
}

.footer {
  grid-column: 1 / -1;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.product-list-item {
  height: 40vh;
  flex-grow: 1;
  margin: 0.7em;
}

.product-list-item:last-child {
  flex-grow: 10;
}

.card-img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* ================================================================= */
/** Les Medias Queries */

@media (max-height: 480px) {
  .product-list-item {
    height: 80vh;
  }
}

@media (max-aspect-ratio: 1/1) {
  .product-list-item {
    height: 30vh;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 580px) {
  .search-bar {
    width: 19em !important;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  .logo {
    margin-left: 0.5em !important;
  }

  .search-bar {
    width: 11em !important;
  }

  .product-list {
    flex-direction: row;
  }

  .product-list-item {
    height: auto;
    width: 100%;
  }
  img {
    width: 100%;
    min-width: 0;
    max-height: 75vh;
  }
}
